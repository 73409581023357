import LOG from './loggerServices';

export class BackgroundService {
  constructor() {
    this.tasks = {};    
  }

  getTask(id) {
    return this.tasks[id];
  }
  
  startTask(id) {
    let task = this.getTask(id);
    if(task && !task.ref) {
      task.ref = window.setInterval(() => {
        LOG.debug('BackgroundService::runningTask', id, task);
        task.fn()
        task.lastRun = (new Date()).getTime();
        task.timesRun++;
      }, task.ms);
    }
  }

  stopTask(id) {
    let task = this.getTask(id);
    if(task) {
      LOG.debug('BackgroundService::taskStopped', id, task);
      window.clearInterval(task.ref);
      task.ref = null;
    }
  }

  registerTask(id, fn, intervalMS, autoStart = true) {    
    this.stopTask(id);
    this.tasks[id] =  { fn: fn, ms: intervalMS || 5 * 1000, timesRun: 0 };

    LOG.debug('BackgroundService::taskRegistered', id, this.tasks[id]);
    
    if(autoStart) {
      this.startTask(id);
      
    }
  }
}

const backgroundService = new BackgroundService();

export default backgroundService;
