/** @format */

import 'isomorphic-fetch';
import { Loader } from 'google-maps';

const PREMIUM_KEY = 'AIzaSyBhupY_Fdor1wqIqEJZZbnxrBA6uDHGO3o';

const options = {
  key: PREMIUM_KEY,//"AIzaSyD0Doud3RR-NxoD06MNrSnISoX-3yMnEaA",
  version: "weekly",
  libraries: ['drawing', 'geometry']
};
const GoogleMapsLoader = new Loader('', options);

/*global google */

class GoogleService {
  get google() {
    return this._google;
  }

  load() {
    if (!this.pLoad) {

      this.pLoad = new Promise(resolve => {
        GoogleMapsLoader
          .load()
          .then(google => {
            this._google = google;
            this._sv = new google.maps.StreetViewService();
            resolve(google);
          })
      });
    }
    return this.pLoad;
  }

  getStreetViewImage(lat, lng, size = [300, 300], heading = 0) {
    return this.load().then(() => {
      if (lat && lng) {
        let latLng = new google.maps.LatLng({ lat, lng });
        return new Promise(resolve => {
          this._sv.getPanorama(
            { location: latLng, radius: 100 },
            (data, status) => {
              let panoId = data && data.location ? data.location.pano : null;
              let hasImage =
                panoId && status === google.maps.StreetViewStatus.OK;
              let img = hasImage
                ? `https://maps.googleapis.com/maps/api/streetview?size=${size[0]
                }x${size[1]}&pano=${panoId}&heading=${heading}&key=${PREMIUM_KEY}`
                : null;

              return resolve(img);
            }
          );
        });
      } else {
        Promise.resolve(null);
      }
    });
  }

  //should be promise? or just assume google.maps exists?, as it always should.
  decodePolyline(encoded) {
    if (!encoded || !encoded.length) return [];

    return google.maps.geometry.encoding
      .decodePath(encoded)
      .map(l => [l.lat(), l.lng()]);
  }

  getInterpolatedPoints(telemetry) {
    return this._snapToRoad(telemetry, true);
  }

  getSpeedLimits(telemetry) {
    return this._snapToRoad(telemetry);
  }

  _snapToRoad(telemetry, interpolate = false) {
    let baseurl = interpolate ? 'snapToRoads' : 'speedLimits';
    let paramId = interpolate ? 'interpolate' : 'units';
    let paramValue = interpolate ? 'true' : 'KPH';

    const fetchData = _path => {
      return fetch(
        `https://roads.googleapis.com/v1/${baseurl}?path=${_path}&key=${PREMIUM_KEY}&${paramId}=${paramValue}`
      )
        .then(r => {
          return r.json();
        })
        .catch(err => {
          return Promise.reject(err);
        });
    };

    return new Promise(resolve => {
      if (!telemetry || !telemetry.length) {
        resolve(telemetry);
      }

      let telem = [].concat(telemetry);
      let groups = [];

      const MAX_GROUP_POS = 100;
      while (telem.length > 0) {
        let group = telem.splice(0, MAX_GROUP_POS);
        groups.push(group);
      }

      let requests = groups.map(g => {
        let path = g
          .map(g => {
            return g.latlng.join(',');
          })
          .join('|');
        return fetchData(path).then(res => {
          let lastIdx = null;
          res.snappedPoints.forEach(sp => {
            let pos = g[sp.originalIndex];
            let speedLimit =
              (res.speedLimits || []).find(x => x.placeId === sp.placeId) || {};
            if (pos) {
              //snap to road only if interpolate = true
              if (interpolate) {
                pos.latitude = sp.location.latitude;
                pos.longitude = sp.location.longitude;
              }
              pos.speedLimit = speedLimit.speedLimit;
              lastIdx = sp.originalIndex;
            } else {
              //inly present if interpolate = true
              g[lastIdx].interpolated = g[lastIdx].interpolated || [];
              g[lastIdx].interpolated.push([
                sp.location.latitude,
                sp.location.longitude
              ]);
            }
          });
          return g;
        });
      });

      Promise.all(requests)
        .then(groups => resolve([].concat.apply([], groups)))
        .catch(() => resolve(telemetry));
    });
  }
}

const googleService = new GoogleService();

export default googleService;
