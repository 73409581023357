/** @format */

import storageService from './storageService';
import uiService from 'services/uiService';
import signalrService from 'services/signalr/signalrService';
const TOKEN_KEY = 'fonix_token';

class AuthService {
  constructor() {
    this.token = storageService.get(TOKEN_KEY);
  }

  logout() {
    signalrService.disconnect();
    uiService.impersonate(null, false);
    this.removeToken();
  }

  setToken(token) {
    storageService.set(TOKEN_KEY, token);
    this.token = token;
  }

  getToken() {
    return this.token;
  }

  removeToken() {
    storageService.remove(TOKEN_KEY);
    this.token = null;
  }
}

const authService = new AuthService();
export default authService
