/**
 * Basic log appender to replace aurelia default console logger
 *
 * @format
 */

//Todo move to confis folder
export const LOG_LEVELS = {
  debug: 'debug',
  prod: 'prod'
};

const LEVEL = LOG_LEVELS.prod;

/*eslint-disable no-console */
export class ConsoleLogger {
  constructor() {
    this.level = LEVEL;
  }

  setLevel(level) {
    this.level = LOG_LEVELS[level] || this.level;
    LOG.debug('LOG', 'log level set:', this.level)
  }
  /**
   * log debug
   */
  debug(logger, message, ...rest) {
    if (this.level == LOG_LEVELS.debug)
      console.log(`DEBUG [${logger}] ${message}`, ...rest);
  }

  /**
   * log info
   */
  info(logger, message, ...rest) {
    if (this.level != LOG_LEVELS.prod)
      console.info(`INFO [${logger}] ${message}`, ...rest);
  }

  /**
   * log wan
   */
  warn(logger, message, ...rest) {
    console.warn(`WARN [${logger}] ${message}`, ...rest);
  }

  /**
   * log error
   */
  error(logger, message, ...rest) {
    console.error(`ERROR [${logger}] ${message}`, ...rest);
  }
}

const LOG = new ConsoleLogger();
export default LOG;
