/** @format */
import LOG from './loggerServices';

export const EventsList = {
  I18NLoaded: 'I18NLoaded',

  AppThemePreviewChanged: 'AppThemePreviewChanged',
  AppRouterChanged: 'AppRouterChanged',

  ApiUpdateRequired: 'ApiUpdateRequired',
  ApiUnauthorized: 'ApiUnauthorized',

  MapServiceZoomChanged: 'MapServiceZoomChanged',
  MapServiceAssetSelected: 'MapServiceAssetSelected',
  MapServicePOISelected: 'MapServicePOISelected',
  MapServiceZoneSelected: 'MapServiceZoneSelected',
  MapServiceMapClick: 'MapServiceMapClick',
  MapServiceDrawEnd: 'MapServiceDrawEnd',
  MapServiceShapes: 'MapServiceShapes',
  MapServiceRequestShapes: 'MapServiceRequestShapes',

  MapContextAssetSelected: 'MapContextAssetSelected',
  MapContextNearby: 'MapContextNearby',
  MapContextTripPoint: 'MapContextTripPoint',
  MapLocationMarker: 'MapLocationMarker',

  POICreated: 'POICreated',
  POIChanged: 'POIChanged',

  ProvisionResellerChanged: 'ProvisionResellerChanged',
  PrivisionAccountChanged: 'PrivisionAccountChanged',

  UserDetailsChanged: 'UserDetailsChanged',

  SignalrbroadcastSnapshot: 'SignalrbroadcastSnapshot',
  SignalrbroadcastAlert: 'SignalrbroadcastAlert',
  SignalrbroadcastMessage: 'SignalrbroadcastMessage'
};

const EventBus = {
  messages: {},

  subscribe(name, callback) {
    if (!this.messages[name]) {
      this.messages[name] = [];
    }
    if (typeof callback === 'function') {
      this.messages[name].push(callback);
    }
    LOG.debug('EventBus: sub', name, this.messages[name].length);
  },

  publish(name, data) {
    LOG.debug('EventBus: publish', name);

    if (this.messages[name]) {
      this.messages[name].forEach(func => func(data));
    }
  },

  unsubscribe(name, fn) {
    if (this.messages[name]) {
      const i = this.messages[name].indexOf(fn);
      if (i < 0) {
        LOG.warn('EventBus: unsub - not found', name, fn);
        return false;
      }

      delete this.messages[name][i];
      this.messages[name].splice(i, 1);

      LOG.debug('EventBus: unsub', this.messages[name].length);

      return true;
    }
  }
};

export class EventService {
  static EVENTS = EventsList;

  constructor() {
    this._bus = EventBus;
  }

  publish(eventName, data) {
    return this._bus.publish(eventName, data);
  }

  subscribe(eventName, fn) {
    return this._bus.subscribe(eventName, fn);
  }

  unsubscribe(eventName, fn) {
    this._bus.unsubscribe(eventName, fn);
  }
}

const eventService = new EventService();
export default eventService;
